import "./App.css";
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Info } from "@mui/icons-material";
import { LicenseInfo } from "@mui/x-license";

const App = () => {
  LicenseInfo.setLicenseKey(
    "6b1cacb920025860cc06bcaf75ee7a66Tz05NDY2MixFPTE3NTMyNTMxMDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const title = "D❤️T",
    innerHeight = window.innerHeight,
    // urlPrefix = window.location.protocol + "//" + window.location.host,
    // { href } = window.location,
    // mode = href.startsWith("http://localhost") ? "local" : "remote",
    // server = href.split("//")[1].split("/")[0],
    // webDavPrefix = urlPrefix + "/lsaf/webdav/repo",
    // fileViewerPrefix = `https://${server}/lsaf/filedownload/sdd:/general/biostat/tools/fileviewer/index.html?file=`,
    [openInfo, setOpenInfo] = useState(false),
    api = "http://81.141.65.156:3000/sql/wedding/",
    [rows, setRows] = useState(null),
    [cols, setCols] = useState(),
    // [sql, setSql] = useState(""),
    sqlResponses =
      "select *,responses.rowid from responses join guests on responses.id=guests.id where responses.rowid in (select max(rowid) from responses group by id)",
    colsResponses = [
      { field: "id", headerName: "ID", width: 100 },
      { field: "name", headerName: "Name", width: 200 },
      { field: "response1", headerName: "Event 1", width: 80 },
      { field: "response2", headerName: "Event 2", width: 80 },
      { field: "response3", headerName: "Event 3", width: 80 },
      { field: "message", headerName: "Message", width: 400 },
    ],
    sqlResponded =
      `SELECT r.id, r.name, ` +
      `CASE WHEN g.townhall != "" THEN CASE WHEN r.response1 IS NOT NULL THEN r.response1 ELSE 'not invited' END ELSE 'not invited' END AS response1, ` +
      `CASE WHEN g.afternoon != "" THEN CASE WHEN r.response2 IS NOT NULL THEN r.response2 ELSE 'not invited' END ELSE 'not invited' END AS response2, ` +
      `CASE WHEN g.evening != "" THEN CASE WHEN r.response3 IS NOT NULL THEN r.response3 ELSE 'not invited' END ELSE 'not invited' END AS response3, ` +
      `r.message FROM responses r ` +
      `JOIN guests g ON r.name = g.name ` +
      `WHERE r.rowid IN ( SELECT MAX(rowid) FROM responses GROUP BY name ) AND ` +
      `(r.response1 IS NOT NULL OR r.response2 IS NOT NULL OR r.response3 IS NOT NULL) ` +
      `ORDER BY r.rowid DESC;`,
    colsResponded = [
      { field: "name", headerName: "Name", width: 200 },
      { field: "response1", headerName: "Event 1", width: 80 },
      { field: "response2", headerName: "Event 2", width: 80 },
      { field: "response3", headerName: "Event 3", width: 80 },
      { field: "message", headerName: "Message", width: 400 },
    ],
    sqlNotYet =
      `SELECT g.id,g.name, g.townhall, g.afternoon, g.evening FROM guests g ` +
      `LEFT JOIN responses r ` +
      `ON g.name = r.name WHERE r.name IS NULL AND (g.townhall != "" OR g.afternoon != "" OR g.evening != "") ` +
      `ORDER BY g.name;`,
    colsNotYet = [
      { field: "name", headerName: "Name", width: 200 },
      { field: "townhall", headerName: "Event 1", width: 80 },
      { field: "afternoon", headerName: "Event 2", width: 80 },
      { field: "evening", headerName: "Event 3", width: 80 },
    ],
    sqlSummary = `SELECT rowid as id, CONCAT( SUM( CASE WHEN g.townhall != "" THEN CASE WHEN LOWER(r.response1) = 'all' THEN g.townhall WHEN LOWER(r.response1) = 'one' THEN 1 ELSE 0 END ELSE 0 END ), '|', SUM(CASE WHEN g.townhall != "" THEN g.townhall ELSE 0 END) ) AS Townhall, CONCAT( SUM( CASE WHEN g.afternoon != "" THEN CASE WHEN LOWER(r.response2) = 'all' THEN g.afternoon WHEN LOWER(r.response2) = 'one' THEN 1 ELSE 0 END ELSE 0 END ), '|', SUM(CASE WHEN g.afternoon != "" THEN g.afternoon ELSE 0 END) ) AS Afternoon, CONCAT( SUM( CASE WHEN g.evening != "" THEN CASE WHEN LOWER(r.response3) = 'all' THEN g.evening WHEN LOWER(r.response3) = 'one' THEN 1 ELSE 0 END ELSE 0 END ), '|', SUM(CASE WHEN g.evening != "" THEN g.evening ELSE 0 END) ) AS Evening FROM guests g LEFT JOIN (SELECT * FROM responses WHERE rowid IN (SELECT MAX(rowid) FROM responses GROUP BY name)) r ON g.name = r.name;`,
    colsSummary = [
      { field: "Townhall", headerName: "Event 1", width: 80 },
      { field: "Afternoon", headerName: "Event 2", width: 80 },
      { field: "Evening", headerName: "Event 3", width: 80 },
    ],
    sqlGuests = `select * from guests`,
    colsGuests = [
      { field: "id", headerName: "ID", width: 100 },
      { field: "name", headerName: "Name", width: 200 },
      { field: "townhall", headerName: "Event 1", width: 80 },
      { field: "afternoon", headerName: "Event 2", width: 80 },
      { field: "evening", headerName: "Event 3", width: 80 },
      { field: "category", headerName: "Category", width: 80 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "link", headerName: "Link", width: 200 },
    ],
    getData = (_sql, _cols) => {
      fetch(
        api + _sql
        // "select *,responses.rowid from responses join guests on responses.id=guests.id where responses.rowid in (select max(rowid) from responses group by id)"
      )
        .then((response) => response.json())
        .then((_data) => {
          console.log(_data);
          setRows(_data.data);
          setCols(_cols);
          // setSql(_sql);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar variant="dense" sx={{ backgroundColor: "#cccccc" }}>
          <Box
            sx={{
              backgroundColor: "#eeeeee",
              color: "green",
              fontWeight: "bold",
              boxShadow: 3,
              fontSize: 16,
            }}
          >
            &nbsp;&nbsp;{title}&nbsp;&nbsp;
          </Box>
          <Button
            onClick={() => getData(sqlResponses, colsResponses)}
            sx={{ ml: 2 }}
            size="small"
            variant="contained"
            color="info"
          >
            Responses
          </Button>
          <Button
            onClick={() => getData(sqlGuests, colsGuests)}
            sx={{ ml: 2 }}
            size="small"
            variant="contained"
            color="info"
          >
            Guests
          </Button>
          <Button
            onClick={() => getData(sqlResponded, colsResponded)}
            sx={{ ml: 2 }}
            size="small"
            variant="contained"
            color="success"
          >
            Responded
          </Button>
          <Button
            onClick={() => getData(sqlNotYet, colsNotYet)}
            sx={{ ml: 2 }}
            size="small"
            variant="contained"
            color="warning"
          >
            Not Yet
          </Button>
          <Button
            onClick={() => getData(sqlSummary, colsSummary)}
            sx={{ ml: 2 }}
            size="small"
            variant="contained"
            color="secondary"
          >
            Summary
          </Button>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Tooltip title="Information about this screen">
            <IconButton
              color="info"
              // sx={{ mr: 2 }}
              onClick={() => {
                setOpenInfo(true);
              }}
            >
              <Info />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Grid container>
        {/* <Grid item xs={12}>
          <> {sql && <p>{sql}</p>}</>
        </Grid> */}
        <Grid item xs={12}>
          <Box sx={{ height: innerHeight - 50, width: "100%" }}>
            {rows && (
              <DataGridPro
                rowHeight={25}
                headerHeight={25}
                sx={{ mt: 7 }}
                rows={rows}
                columns={cols}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                // sx={{ "& .MuiDataGrid-row": { fontSize: fontSize } }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      {/* Dialog with General info about this screen */}
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={() => setOpenInfo(false)}
        open={openInfo}
      >
        <DialogTitle>Info about this screen</DialogTitle>
        <DialogContent>
          <Box sx={{ color: "blue", fontSize: 11 }}>Description goes here.</Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default App;
